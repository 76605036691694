import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const employeeRoutesDictionary = moduleRouteDictionaryFactory(
  'employees',
  'employees',
  {
    list: 'list',
    detail: 'detail/:id',
    editAnagraphic: 'edit/:id/anagraphic',
    editCompany: 'edit/:id/company',
    inviteMany: 'invite-many',
  },
);
